import React, { FC } from 'react';
import { PageBlock } from '@design-system/page-block';

import BodyRender from 'components/BodyRender';

import { ContentBlockProps } from './models';

const ContentBlock: FC<ContentBlockProps> = ({ pageBlock, content, currentPageUid }) => (
  <PageBlock {...pageBlock} className="content-block">
    <BodyRender items={content} currentPageUid={currentPageUid} />
  </PageBlock>
);

export default ContentBlock;
